import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Jumbo from "../components/jumbo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something grecv cfvfcdgj kat.</p> */}
    <div style={{ position: `relative`, height: `100vh`}}>
      <div style={{ margin: `0`, position: `absolute`, top: `50%`, left: `50%`, transform: `translate(-50%, -50%)`, maxWidth: `500px`, width: `100%`}}>
        <Image />
      </div>
    </div>

    {/* <Image /> */}
  </Layout>
)

export default IndexPage
